import React, { useState } from "react";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarMenu,
  SidebarLink,
  SideBtnWrap,
} from "./SidebarElements";

import { FaPizzaSlice } from "react-icons/fa";

const Sidebar = ({ isOpen, toggle }) => {
  const today = new Date();

  const newMenuCheck = new Date(Date.parse("2022-12-28"));
  const [showNewMenu] = useState(today >= newMenuCheck);

  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarMenu>
        <SidebarLink
          to="/"
          onClick={() =>
            (window.location.href = showNewMenu
              ? "/listino-pizzeria-2024.pdf"
              : "/listino-pizzeria-2022.pdf")
          }
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <FaPizzaSlice color="#e9ba23" style={{ marginRight: "1.5em" }} />{" "}
            <span>NUOVO MENÙ</span>
            <FaPizzaSlice color="#e9ba23" style={{ marginLeft: "1.5em" }} />
          </div>
          {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}></div>
                    <FaPizzaSlice color="#e9ba23" />{" "}
                    <div style={{ display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center" }}>
                        <span>NUOVO MENÙ</span>
                    </div>
                    <FaPizzaSlice color="#e9ba23" /> */}
        </SidebarLink>
        <SidebarLink
          to="/"
          onClick={() => {
            window.location.href = "https://wa.me/393792407433";
          }}
        >
          WhatsApp
        </SidebarLink>
        <SidebarLink
          to="/"
          onClick={() => {
            window.location.href = "tel:0456180120";
          }}
        >
          Telefono
        </SidebarLink>
      </SidebarMenu>
      <SideBtnWrap>
        {/* <SidebarRoute to="/">NOVITÀ</SidebarRoute> */}
      </SideBtnWrap>
    </SidebarContainer>
  );
};

export default Sidebar;
