import React from "react";
import { Nav, NavLink, NavIcon, Bars } from "./NavbarElements";
import ReactLogo from "./risorsa_1.svg";

const Navbar = ({ toggle }) => {
    return (
        <>
            <Nav>
                <NavLink to="/">
                    <img
                        src={ReactLogo}
                        alt="React Logo"
                        style={{ width: "100px" }}
                    />
                </NavLink>
                <NavIcon onClick={toggle}>
                    <h2>Menu</h2>
                    <Bars />
                </NavIcon>
            </Nav>
        </>
    );
};

export default Navbar;
