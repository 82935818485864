import React from "react";
import { FeatureContainer } from "./FeatureElements";

const Maps = () => {
  return (
    <FeatureContainer>
      <h1>Dove Siamo</h1>
      <br />
      <div class="mapouter">
        <div class="gmap_canvas">
          <iframe
            style={{ width: "90vw", height: "60vh" }}
            src={
              "https://maps.google.com/maps?q=gazzolo%20pizzeria%20zero%20sei&t=k&z=15&ie=UTF8&iwloc=&output=embed"
            }
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            title="maps"
          ></iframe>
          <a href="https://123movies-to.org"> </a>
          <br />
          <a href="https://www.embedgooglemap.net"> </a>
        </div>
      </div>
    </FeatureContainer>
  );
};

export default Maps;
