import React from "react";
import "primeicons/primeicons.css"; //icons
import {
  NewMenuBannerContainer,
  NewMenuBannerFlex,
  NewMenuBannerDiv,
  NewMenuBannerIcon,
} from "./NewMenuBannerElements";

import { FaMinus } from "react-icons/fa";

import menuImg from "../../listino-pizzeria-2024.jpeg";

const NewMenuBanner = ({ show, setShow }) => {
  return (
    <>
      {show && (
        <NewMenuBannerContainer>
          <NewMenuBannerFlex>
            <FaMinus
              style={{
                fontSize: "2em",
                color: "#150f0f",
              }}
            />
            <NewMenuBannerDiv>
              <h2>Nuove pizze e nuovo menu!!!</h2>
              <h3>Clicca sull'immagine per scaricarlo</h3>
              <br />
              <img
                src={menuImg}
                onClick={() =>
                  (window.location.href = "/listino-pizzeria-2024.pdf")
                }
                style={{ cursor: "pointer" }}
                height="80%"
                alt="listino"
              ></img>
            </NewMenuBannerDiv>
            {/* <FaMinus
                            style={{
                                fontSize: "2em",
                                color: "#150f0f",
                            }}
                        /> */}
            <div>
              <NewMenuBannerIcon
                onClick={() => {
                  setShow(false);
                }}
              >
                <i
                  className="pi pi-times"
                  style={{
                    fontSize: "2em",
                    marginRight: "0.5em",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                />{" "}
                Chiudi
              </NewMenuBannerIcon>
            </div>
          </NewMenuBannerFlex>
        </NewMenuBannerContainer>
      )}
    </>
  );
};

export default NewMenuBanner;
