import React from "react";
import { WhatsAppContainer } from "./WhatsAppElements";

import scr from "../../images/screeshot_iphone.png";

const WhatsApp = () => {
  return (
    <WhatsAppContainer>
      <h1>Come ordinare online</h1>
      <p>Ecco un messaggio di esempio per ordinare tramite whatsapp!</p>
      <div>
        <img src={scr} alt="screenshot"></img>
      </div>
    </WhatsAppContainer>
  );
};

export default WhatsApp;
