import React, { useState } from "react";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import {
  HeroContainer,
  HeroContent,
  HeroItems,
  HeroH1,
  HeroP,
  HeroBtn,
} from "./HeroElements";

import Heading from "../../images/test1.png";

const Hero = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <HeroContainer>
      <Navbar toggle={toggle} />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <HeroContent>
        <HeroItems>
          <HeroH1>
            <img src={Heading} alt="heading"></img>
          </HeroH1>
          <HeroP>Gazzolo d'Arcole (vr)</HeroP>
          <HeroP style={{ marginTop: -30 }}>Via Casalveghe 16</HeroP>
          <HeroBtn
            onClick={() => {
              window.location.href = "tel:0456180120";
            }}
          >
            Chiamaci!
          </HeroBtn>
          <HeroP style={{ marginTop: "20px" }}>
            Scopri la nostra Fidelty Card ed il nuovo Menù
          </HeroP>
        </HeroItems>
      </HeroContent>
    </HeroContainer>
  );
};

export default Hero;
