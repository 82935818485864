import React from "react";
import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";
import {
    FooterContainer,
    FooterWrap,
    SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    SocialIcons,
    SocialIconLink,
} from "./FooterElements";
import zeroSeiLogo from "./risorsa_1.svg";

const Footer = () => {
    return (
        <FooterContainer>
            <FooterWrap>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo>
                            <img
                                src={zeroSeiLogo}
                                alt="React Logo"
                                style={{ width: "100px" }}
                            />
                        </SocialLogo>
                        <SocialLogo>
                            <p style={{ fontSize: "1.5em" }}>
                                Clicca qui per i nostri canali social
                            </p>
                        </SocialLogo>
                        <SocialIcons>
                            <SocialIconLink
                                href="https://wa.me/393792407433"
                                target="_blank"
                                aria-label="Whatsapp"
                            >
                                <FaWhatsapp />
                            </SocialIconLink>
                            <SocialIconLink
                                href="https://www.facebook.com/pizzeriazerosei"
                                target="_blank"
                                aria-label="Facebook"
                            >
                                <FaFacebook />
                            </SocialIconLink>
                            <SocialIconLink
                                href="https://www.instagram.com/zeroseilapizza/"
                                target="_blank"
                                aria-label="Instagram"
                            >
                                <FaInstagram />
                            </SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    );
};

export default Footer;
