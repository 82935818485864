import React, { useState } from "react";
import { GlobalStyle } from "./globalStyles";
import Hero from "./components/Hero";
import { Products1, Products2 } from "./components/Products";
import { productData, productData2 } from "./components/Products/data";
import Feature from "./components/Feature";
import Maps from "./components/Maps";
import Footer from "./components/Footer";
import Orari from "./components/Orari";
import WhatsApp from "./components/WhatsApp";

import FideltyCard from "./components/FideltyCard";

import Poster from "./components/Poster";
import SummerVacation from "./components/SummerVacation";
import NewMenuBanner from "./components/NewMenuBanner";
// import PromoBanner from "./components/PromoBanner";

const Home = () => {
  //   const today = new Date();

  // poster per immagini
  //   const check = new Date(Date.parse("2022-03-03"));
  // const [show, setShow] = useState(today < check);
  const [show, setShow] = useState(false);

  // elemento per le ferie estive
  //   const summerVacationCheck = new Date(Date.parse("2023-07-25"));
  // const [showSummerVacation, setShowSummerVacation] = useState(today < summerVacationCheck);
  const [showSummerVacation, setShowSummerVacation] = useState(false);

  // elemento per nuovo menu
  //   const newMenuCheck = new Date(Date.parse("2022-12-28"));
  // const [showNewMenu, setShowNewMenu] = useState(today < newMenuCheck);
  const [showNewMenu, setShowNewMenu] = useState(true);

  // const [showPromoBanner, setShowPromoBanner] = useState(true);

  return (
    <>
      <GlobalStyle />
      <Poster show={show} setShow={setShow} />
      <SummerVacation
        show={showSummerVacation}
        setShow={setShowSummerVacation}
      />
      <NewMenuBanner show={showNewMenu} setShow={setShowNewMenu} />
      {/* <PromoBanner show={showPromoBanner} setShow={setShowPromoBanner} /> */}
      {!show && (
        <>
          <Hero />
          <FideltyCard />
          <Products1 heading="Alcune delle nostre pizze" data={productData} />
          <Feature />
          <Products2 heading="La nostra Offerta" data={productData2} />
          <WhatsApp />
          <Orari />
          <Maps />
          <Footer />
        </>
      )}
    </>
  );
};

export default Home;
