import product1 from "../../images/salmone-lime.jpg";
import product2 from "../../images/stracciatella.jpg";
import product3 from "../../images/balsamica.jpg";

export const productData = [
    {
        img: product1,
        alt: "Pizza",
        name: "Salmone e Lime",
        desc: "Bufala Campana DOP, philadelphia, in uscita salmone affumicato, scorza di lime grattuggiata",
        price: "€ 9,00",
        button: "Aggiungi al carrello",
    },
    {
        img: product2,
        alt: "Pizza",
        name: "Stracciatella",
        desc: "Pomodoro, pomodorini, in uscita prosciutto crudo di Parma, stracciatella di Bufala, basilico fresco, olio EVO",
        price: "€ 10,00",
        button: "Aggiungi al carrello",
    },
    {
        img: product3,
        alt: "Pizza",
        name: "Balsamica",
        desc: "Bufala Campana DOP, raddichio, in uscita pancetta arrotoloata, grana a scaglie, glassa d'aceto balsamico, basilico fresco",
        price: "€ 9,00",
        button: "Aggiungi al carrello",
    },
];
export const productData2 = [
    {
        name: "Servizio a domicilio",
        desc: `
        Sempre puntale e veloce!
        Whatsapp e chiamate per la migliore organizzazione!`,
    },
    {
        name: "Diversi impasti",
        desc: `
        Integrale, impasto classico con pallina da 180g e Verace con pallina da 260g bordo alto (tipo napoletano)

        `,
    },
];

export const productData3 = [];
