import React from "react";
import { FeatureContainer } from "./FeatureElements";

const Feature = () => {
  return (
    <FeatureContainer>
      {/* cambiata in data 28-10-2021 */}
      {/* <p>
                La pizza del mese
                <br />
            </p>
            <h1>La calabrese</h1>
            <p>
                Pomodoro, bufala, melanzane, nduja, olive, stracciatella,
                basilico
            </p> */}
      {/* <FeatureButton>Ordina Ora!</FeatureButton> */}
      {/* cambiata in data 07-01-2022 */}
      {/* <p>
                La pizza del mese
                <br />
            </p>
            <h1>L'autunno</h1>
            <p>Crema di zucca, bufala, misto bosco, speck e grana a scaglie</p>
            <p>€ 8,50</p> */}
      {/* rimossa in data 04-02-2022 */}
      {/* <p>
                La pizza del mese
                <br />
            </p>
            <h1>La Carbonara</h1>
            <p>
                Bufala, pancetta affumicata, in uscita crema tuorlo d'uovo,
                pecorino e pepe
            </p>
            <p>€ 8,50</p> */}
      {/* <p>
                La pizza del mese
                <br />
            </p>
            <h1>La Campagnola</h1>
            <p>
                Mozzarella di Bufala, Asparagi ed Uovo alla Bismark. In uscita
                pancetta arrotolata
            </p>
            <p>€ 9,00</p> */}
      {/* <p>
                La pizza del mese
                <br />
            </p>
            <h1>La Fiorita</h1>
            <p>
                Mozzarella di Bufala, fiori di zucca, acciughe e ricotta fresca
            </p>
            <p>€ 9,00</p> */}
      {/* <p>
                La pizza del mese
                <br />
            </p>
            <h1>ZERO SEI</h1>
            <p>Bufala campana, patate al forno, Porchetta D'Ariccia scamorza, cipolla caramellata</p>
            <p>€ 9,50</p> */}
      {/* <p>
                La pizza del mese
                <br />
            </p>
            <h1>La Campagnola</h1>
            <p>Crema di Asparagi, Bufala, Uovo, Speck, Scaglie di Percorino, Pepe Macinato</p>
            <p>€ 9,80</p> */}
      {/* <p>
                La pizza del mese
                <br />
            </p>
            <h1>La Mortazza</h1>
            <p>
                Bufala, Mortadella, Stracciatella, Pesto di Pistacchi e Basilico
            </p>
            <p>€ 9,90</p> */}
      {/* <p>
                La pizza del mese
                <br />
            </p>
            <h1>La BigGusto</h1>
            <p>
                Metà calzone con mozzarella, scamorza e speck e Metá pizza base
                margherita con Rucola pomodorini e grana a scaglie
            </p>
            <p>€ 9,00</p> */}
      <p>
        La pizza del mese
        <br />
      </p>
      <h1>La Pizza Kinder</h1>
      <p>
        Nutella, granella di nocciole, kinder bueno, Ferreo Rocher, Kinder
        Cioccolato, Zucchero a velo
      </p>
      <p>€ 9,00</p>
    </FeatureContainer>
  );
};

export default Feature;
