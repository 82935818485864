import React from "react";
import "primeicons/primeicons.css"; //icons
import {
  SummerVacationContainer,
  SummerVacationFlex,
  SummerVacationDiv,
  SummerVacationIcon,
} from "./SummerVacationElements";

import { FaMinus } from "react-icons/fa";

const SummerVacation = ({ show, setShow }) => {
  return (
    <>
      {show && (
        <SummerVacationContainer>
          <SummerVacationFlex>
            <FaMinus
              style={{
                fontSize: "2em",
                color: "#150f0f",
              }}
            />
            <SummerVacationDiv>
              <h1>
                Siamo chiusi per ferie dal 17 al 25 Luglio, riapriamo Mercoledì
                26 Luglio !!!
              </h1>
            </SummerVacationDiv>
            {/* <FaMinus
                            style={{
                                fontSize: "2em",
                                color: "#150f0f",
                            }}
                        /> */}
            <div>
              <SummerVacationIcon
                onClick={() => {
                  setShow(false);
                }}
              >
                <i
                  className="pi pi-times"
                  style={{
                    fontSize: "2em",
                    marginRight: "0.5em",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                />{" "}
                Chiudi
              </SummerVacationIcon>
            </div>
          </SummerVacationFlex>
        </SummerVacationContainer>
      )}
    </>
  );
};

export default SummerVacation;
