import React from "react";
import { OrariContainer } from "./OrariElements";

const Orari = () => {
    return (
        <OrariContainer>
            <h1>I nostri orari</h1>
            <p>Siamo Aperti dal martedì alla domenica dalle 17:30 alle 21:30</p>
            <p style={{ color: "#ffc500" }}>
                Mercoledi, Giovedi consegna a domicilio gratuita esclusi festivi e prefestivi
            </p>
            <p>
                Per ordinare con WhatsApp è possibile solo entro le 17:30 ed è
                necessaria una nostra conferma
            </p>
            <p>
                Oltre le 17 è possibile ordinare chiamando negli orari normali
            </p>
            <p style={{ color: "red" }}>Lunedi chiuso</p>
        </OrariContainer>
    );
};

export default Orari;
