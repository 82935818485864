import React, { useState } from "react";
import { FeatureButton } from "../Feature/FeatureElements";
import {
  ProductsContainer,
  ProductWrapper,
  ProductsHeading,
  ProductTitle,
  ProductCard,
  ProductImg1,
  ProductInfo,
  ProductDesc,
  ProductPrice,
} from "./ProductsElements";

import { FaPizzaSlice } from "react-icons/fa";

export const Products1 = ({ heading, data }) => {
  const today = new Date();

  // elemento per nuovo menu
  const newMenuCheck = new Date(Date.parse("2022-12-28"));
  const [showNewMenu] = useState(today >= newMenuCheck);

  return (
    <ProductsContainer>
      <ProductsHeading>{heading}</ProductsHeading>
      <ProductWrapper>
        {data.map((product, index) => {
          return (
            <ProductCard key={index}>
              <ProductImg1 src={product.img} alt={product.alt} />
              <ProductInfo>
                <ProductTitle>{product.name}</ProductTitle>
                <ProductDesc>{product.desc}</ProductDesc>
                <ProductPrice>{product.price}</ProductPrice>
              </ProductInfo>
            </ProductCard>
          );
        })}
      </ProductWrapper>
      <center>
        <FeatureButton
          onClick={() =>
            (window.location.href = showNewMenu
              ? "/listino-pizzeria-2024.pdf"
              : "/listino-pizzeria-2022.pdf")
          }
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <FaPizzaSlice color="#150f0f" style={{ marginRight: "1.5em" }} />{" "}
            <span>NUOVO MENÙ</span>
            <FaPizzaSlice color="#150f0f" style={{ marginLeft: "1.5em" }} />
          </div>
        </FeatureButton>
      </center>
    </ProductsContainer>
  );
};
export const Products2 = ({ heading, data }) => {
  return (
    <ProductsContainer>
      <ProductsHeading>{heading}</ProductsHeading>
      <ProductWrapper>
        {data.map((product, index) => {
          return (
            <ProductCard key={index}>
              <ProductInfo>
                <ProductTitle>{product.name}</ProductTitle>
                <ProductDesc>{product.desc}</ProductDesc>
                <ProductPrice>{product.price}</ProductPrice>
              </ProductInfo>
            </ProductCard>
          );
        })}
      </ProductWrapper>
    </ProductsContainer>
  );
};
