import front from "../../images/fidelty_card_front_v2.jpeg";
import back from "../../images/fidelty_card_back_v2.jpeg";

import { FideltyCardContainer } from "./FideltyCardElements";

import "./fidelty.css";

const FideltyCard = () => {
  return (
    <FideltyCardContainer id={"fildery-card"}>
      <h1>NUOVA FIDELTY CARD!</h1>
      {/* <p>Clicca sulla card per scoprire come funziona!</p> */}
      <div className="scene">
        <div className="card">
          <div className="face front">
            <img className="image-fid" src={front} alt="face front" />
          </div>
          <div className="face back">
            <img className="image-fid" src={back} alt="face back" />
          </div>
        </div>
      </div>
    </FideltyCardContainer>
  );
};

export default FideltyCard;
