import React from "react";
import "primeicons/primeicons.css"; //icons
import {
  PosterContainer,
  PosterFlex,
  PosterImage,
  PosterIcon,
} from "./PosterElements";

import { FaMinus } from "react-icons/fa";

import PosterPic from "../../images/PosterPic.jpg";

const Poster = ({ show, setShow }) => {
  return (
    <>
      {show && (
        <PosterContainer>
          <PosterFlex>
            <FaMinus
              style={{
                fontSize: "2em",
                color: "#150f0f",
              }}
            />
            <PosterImage src={PosterPic} />
            {/* <FaMinus
                            style={{
                                fontSize: "2em",
                                color: "#150f0f",
                            }}
                        /> */}
            <div>
              <PosterIcon
                onClick={() => {
                  setShow(false);
                }}
              >
                <i
                  className="pi pi-times"
                  style={{
                    fontSize: "2em",
                    marginRight: "0.5em",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                />{" "}
                Chiudi
              </PosterIcon>
            </div>
          </PosterFlex>
        </PosterContainer>
      )}
    </>
  );
};

export default Poster;
