import styled from "styled-components";

export const NewMenuBannerContainer = styled.div`
    z-index: 2000;
    background: #150f0f;
    height: 100vh;
    weight: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1em;
    margin: auto;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

export const NewMenuBannerFlex = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1em;
    color: #fff;
    @media screen and (max-width: 900px) {
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;
    }
`;

export const NewMenuBannerDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media screen and (max-width: 700px) {
        width: 100vw;
    }
    @media screen and (max-width: 900px) {
        height: 80vh;
    }
    @media screen and (min-width: 901px) {
        height: 95vh;
    }
`;

export const NewMenuBannerIcon = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    @media screen and (max-width: 900px) {
        margin-bottom: 1em;
        margin-top: 1em;
    }
`;
